<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享区块链超级工作站</p>
        <p class="header-desc">
          众享区块链超级工作站是众享科技自主研发的软硬云一体化区块链应用产品，实现物理主机+区块链网络+应用系统+运维监控全场景服务。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          众享区块链超级工作站是众享科技自主研发的软硬云一体化区块链应用产品，实现物理主机+区块链网络+应用系统+运维监控全场景服务。该产品可广泛应用于中央政治局集体学习中提到的金融业、商业、民生、智慧城市、城际互通及政务服务六大应用场景。<br /><br />众享区块链超级工作站采用超融合设计，开箱插电即用，一键启动，区块链系统自动部署、分布式文件系统自动搭建、应用系统自动启动、运维系统主动管理，无需专业区块链部署和维护技术人员，助力各行各业像使用个人电脑一样轻松使用区块链。
        </p>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <div class="line-title">
          <div></div>
          <div>身份认证体系</div>
          <div></div>
        </div>
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-1.png"
                />
              </div>
              <div class="title">身份校验</div>
              <div class="text">
                基于公私钥体系验证登录平台的用户身份，保障系统安全
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-2.png"
                />
              </div>
              <div class="title">权限分级</div>
              <div class="text">
                基于多级账户体系设计，解决企业多层级用户管理
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-3.png"
                />
              </div>
              <div class="title">隐私保护</div>
              <div class="text">
                支持国密SM2/3/4算法及国际算法，对用户数据进行私密性保护
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="line-title">
          <div></div>
          <div>多源数据存证</div>
          <div></div>
        </div>
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-4.png"
                />
              </div>
              <div class="title">支持多种存证类型</div>
              <div class="text">
                可在线存证视频、音频、文件、图片、网页等多种类型
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-5.png"
                />
              </div>
              <div class="title">存证数据实时上链</div>
              <div class="text">数据实时上链存储确保存证信息完整、不被篡改</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-6.png"
                />
              </div>
              <div class="title">操作留痕</div>
              <div class="text">用户在系统内的操作可追溯、易定责</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-7.png"
                />
              </div>
              <div class="title">数据保全校验</div>
              <div class="text">
                用户可随时校验数据原始性，真实性，实现一站式数据保全
              </div>
            </div></el-col
          >
        </el-row>
        <div class="line-title">
          <div></div>
          <div>可视化监控运维</div>
          <div></div>
        </div>
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-8.png"
                />
              </div>
              <div class="title">区块链监控</div>
              <div class="text">实时可视化监控区块、交易等信息</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-9.png"
                />
              </div>
              <div class="title">自动告警</div>
              <div class="text">服务器状态异常自动告警</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/function-10.png"
                />
              </div>
              <div class="title">区块链治理</div>
              <div class="text">通过可视化操作管理区块链</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/super-workstation/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/advantage-1.png"
                />
              </div>
              <div class="title">开箱即用易部署</div>
              <div class="text">
                一机四节点均预装全套自动化系统，区块链系统自动部署、分布式文件系统自动搭建、应用系统自动启动、运维系统主动管理。无需专业技术人员组装或配置，开箱插电即用
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/advantage-2.png"
                />
              </div>
              <div class="title">可视操控易运维</div>
              <div class="text">
                区块链浏览器可视化呈现区块链内部区块及交易数据，系统构建自动检测、容错和适配机制，具备高免运维率
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/advantage-3.png"
                />
              </div>
              <div class="title">自主可控更安全</div>
              <div class="text">
                众享区块链超级工作站由北京众享比特科技有限公司自主开发，拥有100%知识产权。内置区块链底层软件ChainSQL支持国密算法
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/advantage-4.png"
                />
              </div>
              <div class="title">多链支持易扩展</div>
              <div class="text">
                支持ChainSQL、Fabric等主流区块链底层平台，底层系统预留通用基础接口，支持多场景应用与硬件设备热插拔，支持叠加硬件扩展至更多节点，支持与第三方BaaS平台融合，助力客户低成本扩展区块链
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 相关项目 -->
    <div class="project">
      <div class="commom-title">相关项目</div>
      <div class="project-content">
        <el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/super-workstation/project-1.png"
                />
              </div>
              <div class="title">ChainSQL</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/super-workstation/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
      .line-title {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(2) {
          padding: 0px 77px;
          font-size: 26px;
          color: #0075ff;
          line-height: 37px;
        }
        div:nth-child(1),
        div:nth-child(3) {
          flex: 1;
          height: 1px;
          background: #0075ff;
        }
      }
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
				padding:24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
					text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }
}
</style>
